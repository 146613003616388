.map-container {
  position: absolute;
  top: 64px;
  bottom: 0;
  width: 100%;
}

.mapboxgl-canvas {
  outline: none;
}

@media screen and (max-width: 600px) {
  .map-container {
    top: 56px;
  }
}

.marker {
  border: none;
  cursor: pointer;
  padding: 0;
  background-repeat: no-repeat;
  background-size: auto;
}

.animating {
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.5),
    rgba(250, 0, 0, 0.5) 10px,
    rgba(50, 50, 50, 0.5) 10px,
    rgba(50, 50, 50, 0.5) 20px
  );
  animation: barberpole 2s linear infinite;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}
